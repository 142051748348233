.login {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .top {
        flex-grow: 1;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
            width: 200px;
        }
    }

    .why {
        text-align: center;
        color: black;
        padding: 0.4rem;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    
    .error {
        text-align: center;
        background-color: darkred;
        color: white;
        padding: 5px;
    }
    
    .button {
        border-radius: 0;
        background-color: #1db954;

        &:hover {
            background-color: darken(#1db954, 5);
        }
    }
    
}
