
.playlist {
    padding: 3.2rem 0 0;
    
    .sort-nav {
        span {
            font-size: calc(1 * .65rem);

            text-transform: uppercase;
            padding: 0.5em 0.75em;

            cursor: pointer;

            &:hover {
                opacity: .8;
            }

            &.sorted {
                font-weight: bold;
                color: #AC1818;
            }

            svg {
                margin-right: 0.2rem;
            }
        }
    }

    .song-list, .stream-title {
        border-top: 1px solid gray;
    }

    .stream-title {
        position: relative;

        p {
            transform: rotate(-90deg);
            transform-origin: 43px 60px;
            font-size: 1rem;
            position: absolute;
            width: 100px;
            top: 0;
        }
    }

    .end {
        padding: 1.2rem 0;
        color: gray;
    }
}

.table {
    background-color: transparent;
    color: white;
    border: none;

    font-size: calc(1 * .65rem);

    thead {
        th {
            color: white;
            border: none;
            padding-bottom: 1.2rem;
            font-weight: normal;
            text-transform: uppercase;
            vertical-align: middle;
        }
    }

    td {
        border: none;
        vertical-align: middle;
        padding: 2rem 0.75rem;
        text-transform: uppercase;

        &.track svg {
            margin-right: 0.8rem;
        }
    }

    tbody tr {
        cursor: pointer;
        transition: .35s ease-in-out;

        &:hover {
            background-color: #545355;
        }
    }
}
