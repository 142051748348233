.modal {
    .modal-card-title {
        font-weight: 700;
    }
    
    button.delete {
        background-color: white;
        
        &::before, &::after {
            background-color: black;
        }
        
        &::before {
            width: 100%;
        }
        
        &::after {
            height: 100%;
        }
        
        &:hover {
            background-color: white;
        }
    }

    .field {
        .label {
            font-weight: normal;
            margin: 0;
            color: lightgray;
            font-size: 0.75rem;
        }

        input, textarea {
            box-shadow: none;
            border: 0;
            border-bottom: 1px solid lightgray;
            border-radius: 0;
            padding: 0;

            &:focus {
                box-shadow: none;
                border-color: gray;
            }

            &.is-danger {
                box-shadow: none;
                border-color: #ff3860;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}
