.post {
    opacity: 1;
    transition: all 0.6s;

    &.just-added {
        opacity: 0;
    }

    .info {
        padding-top: calc(1 * 4rem);
        padding-bottom: calc(1 * 2rem);
        font-size: calc(1 * .875rem);

        p, a{
            margin-bottom: calc(1 * 1rem);

            &.message {
                line-height: 1.6rem;
                font-size: calc(1 * .75rem);
            }
        }

        a {
            margin-top: 8px;
            color: rgba(0,0,0,.5);
            display: block;
            transition: .25s ease-in-out;

            &:hover {
                opacity: .8;
            }
        }
    }

    .track {
        background-color: #E6E7E8;
        padding: calc(1 * 2rem) 0;

        h5, .h5 { font-size: calc(1 * .875rem); }
        h6, .h6 { font-size: calc(1 * .65rem); }

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        hr {
            background-color: #ccc;
            margin: calc(1 * 2rem) 0;
        }

        .reactions {
            .likes, .comments {
                svg {
                    font-size: 1rem;
                    margin-right: 0.4rem;
                    transition: 125ms ease-in-out;

                    &:hover {
                        transform: scale(1.2);
                        opacity: 0.8;
                    }
                }

                font-size: calc(1 * .65rem);
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                
                .count {
                    padding-right: calc(1 * 1rem);
                    padding-left: calc(1 * 1rem);
                }
            }
            .comments {
                padding-left: calc(1 * 2rem);
            }
        }
    }
}
