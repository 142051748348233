.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 3rem;
        font-weight: bold;
    }
}
