.track-preview {
    background-color: #E6E7E8;;
    padding: 1rem;

    p {
        color: black;
        font-weight: bold;
    }
    margin-bottom: 1.6rem;
}
