.top-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        a {
            margin-right: 1.6rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    a {
        font-size: calc(1 * .65rem);
        text-decoration: underline;
        color: black;
    }
}

.posts {
    padding: 1.6rem 0;

    .end {
        text-align: center;
        padding: 4.8rem 0
    }
}
