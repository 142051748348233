.comments-box {
    padding: 1.8rem 0 0;

    .title {
        margin: 0 0 0.8rem;
    }

    .label {
        font-weight: normal;
        font-size: 0.875rem;
        margin-bottom: 0.4rem;
    }

    input {
        box-shadow: none;
        border-radius: 0;
        border: none;

        &:focus {
            box-shadow: none;
            border: none;
        }
    }

    .loading-comments {
        padding: 0.8rem 0;
        font-size: 0.875rem;
    }

    .comment {
        padding: 0.8rem 0;

        .comment-text, .comment-info {
            font-size: 0.65rem;
            font-weight: 400;
            margin: 0;
        }

        .comment-text {
            margin-bottom: 0.4rem;
            word-break: break-word;
        }

        .comment-info {
            color: #999;
        }
    }
}
