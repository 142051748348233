$black:        hsl(0, 0%, 4%);
$black-bis:    hsl(0, 0%, 7%);
$black-ter:    hsl(0, 0%, 14%);

$grey-darker:  hsl(0, 0%, 21%);
$grey-dark:    hsl(0, 0%, 29%);
$grey:         hsl(0, 0%, 48%);
$grey-light:   hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);
$grey-lightest: hsl(0, 0%, 93%);

$white-ter:    hsl(0, 0%, 96%);
$white-bis:    hsl(0, 0%, 98%);
$white:        hsl(0, 0%, 100%);

$orange:       hsl(14,  100%, 53%);
$yellow:       hsl(48,  100%, 67%);
$green:        hsl(141, 71%,  48%);
$turquoise:    hsl(171, 100%, 41%);
$cyan:         hsl(204, 86%,  53%);
$blue:         hsl(217, 71%,  53%);
$purple:       hsl(271, 100%, 71%);
$red:          hsl(348, 100%, 61%);

// Modal variables
$modal-card-head-background-color: white;
$modal-card-head-radius: 0px;
$modal-card-head-border-bottom: none;
$modal-card-title-size: 1rem;
$modal-card-foot-radius: 0px;
$modal-card-foot-border-top: none;
$modal-card-head-padding: 40px;
$modal-card-body-padding: 0px 40px;
$modal-content-width: 500px;
