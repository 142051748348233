.navbar {
    transition: width 0.2s;
    display: flex;
    flex-direction: column;
    width: 70px;
    height: 100vh;
    position: fixed;
    top: 0;

    .nav-item-border {
        margin: 0;
        width: 50%;
        height: 1px;
        background-color: #E6E7E8;
        display: block;
        transition: width .25s ease;
    }

    .nav-item-border {
        margin: 0;
        width: 50%;
        height: 1px;
        background-color: #E6E7E8;
        display: block;
        transition: width .2s ease;
    }

    .navbar-menu {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-between;
        flex-grow: 1;

        background-color: white;
        box-shadow: none;
        padding: 0;

        .navbar-start {
            flex-direction: column;
            margin-right: 0;

            .navbar-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: .25s ease-in-out;

                &.active .user-icon {
                    border: 3px solid orange;
                }

                &:hover {
                    background-color: #f2f2f2;
                    color: #4a4a4a;

                    &+ .nav-item-border {
                        width: 100%!important;
                        border-color: gray;
                        background-color: gray;
                    }
                }
            }
        }

        .navbar-end {
            margin: 0;
            justify-content: flex-end;

            .navbar-item {
                padding: 0;
                width: 100%;

                .button {
                    border-radius: 0;
                    transition: opacity .25s ease;

                    &:hover {
                        background-color: #414042;
                    }
                }
            }
        }
    }
}

.navbar {
    .navbar-brand {
        padding: 1rem 0;
        
        .logo {
            width: 25px;
            margin-left: 0.7rem;
        }
    }

    .description-item {
        transition: opacity .25s ease;
        opacity: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .user-icon {
        background-color: black;
        color: white;
        border-radius: 20%;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &.purple { background-color: purple }
        &.green { background-color: green }
        &.darkblue { background-color: darkblue }
        &.violet { background-color: violet }

        &.user-image {
            background-size: contain;
        }
    }

    .logout {
        opacity: 0;
    }
}

.navbar:hover {
    width: 200px;

    .nav-item-border {
        width: 90%;
        height: 1px;
    }

    .description-item {
        opacity: 1;
    }

    .logout {
        opacity: 1;
    }
}
