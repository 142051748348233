@import "./variables.scss";
@import "bulma/bulma.sass";

html {
    overflow-y: auto;
    background-color: #f7f5f7;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.button {
    border-radius: 0px;

    &.is-vf {
        font-size: 0.75rem;
        -webkit-appearance: none;
        background-color: #e6e7e8;
        color: #000;
        text-transform: uppercase;
        transition: 0.25s ease-in-out;
        padding: 0.6rem 4rem 1.6rem;

        &:hover {
            background-color: #000;
            color: #fff;
        }

        &:focus {
            box-shadow: none;
            border: none;
        }
    }
}

.caps {
    text-transform: uppercase;
}

.main {
    padding-top: 80px;
    padding-left: 70px;
}

.player-control {
    &.back svg,
    &.forward svg {
        width: 0.7em;
    }

    &.play {
        svg {
            width: 0.8em;
        }
    }

    svg {
        transition: 125ms ease-in-out;
        cursor: pointer;

        &:hover {
            transform: scale(1.2);
            opacity: 0.8;
        }
    }
}
