@import "../../../variables.scss";

.invite-modal {
    &.is-active {
        display: block;
    }

    .modal-card {
        top: 76px;
        left: 70px;
        width: calc(100vw - 70px);
        margin: 0;

        .modal-card-body {
            padding: 0;

            .form, .results {
                width: 450px;
                margin: 0 auto;

                .label {
                    text-align: center;
                    color: gray;
                }

                input {
                    padding: 0.6rem;
                }

                .input, .input:focus, .input:active, .control, .control:focus {
                    box-shadow: none;
                    outline: none;
                }

                .control.input {
                    flex-grow: 1;

                    & + .control .button {
                        border: none;
                        border-bottom: 1px solid lightgray;

                        &.has-joined {
                            color: $green;
                        }

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    & + .control.is-danger .button {
                        border: none;
                        border-bottom: 1px solid $red;;
                    }

                    &:focus + .control .button {
                        border-bottom: 1px solid gray;
                    }
                }
            }

            .result {
                padding: 0.6rem;
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                justify-content: space-between;

                &:hover {
                    background-color: #E6E7E8;
                }

                span {
                    font-weight: bold;
                }
            }
        }
    }
}
