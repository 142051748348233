@import "../../variables.scss";

.player {
    background-color: #414042;
    color: white;
    margin-bottom: 0.8rem;
    position: fixed;
    width: calc(100vw - 70px);
    top: 0;
    left: 70px;
    right: 0;
    z-index: 10;

    .top-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.2rem 2rem;
        border-bottom: 1px solid #545355;
    }

    .controls {
        display: flex;
        align-items: center;

        .player-control {
            display: flex;
            width: 45px;
            height: 36px;
            align-items: center;
            justify-content: center;
            border-right: 1px solid gray;
            user-select: none;

            &:last-child {
                border-right: none;
            }
            
            &.play {
                width: 60px;
            }

            &.on {
                color: $red;
            }
        }
    }
}
